
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.chat-display {
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 64px;

  &__message {
    width: fit-content;
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 8px;
    word-wrap: break-word;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    &-received {
      background-color: $color-primary;
      margin-right: auto;
      color: #fff;
    }
    &-sent {
      background-color: $color-section;
      margin-left: auto;
    }
  }
}
