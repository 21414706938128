
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-detail-card {
  max-width: 100%;
  padding: 0.5rem;
  border-radius: $radius;
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-thumbnail {
  width: 6rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: $radius;
}

.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &__label {
    width: 30%;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  &__value {
    flex: 1;
    font-weight: 500;
  }
}
