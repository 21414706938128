
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.offer-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > label {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}
