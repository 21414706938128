
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.chat-page {
  position: relative;
  width: 768px;
  height: calc(100vh - 100px);
  margin: auto;
  padding: 16px;
  background: linear-gradient(to bottom, $color-section, $color-background);

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;

    &-button {
      font-size: x-large;
    }
  }
}
