
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
@use './scss/base/reset';
@use './scss/base/fonts';

body {
  font-family: 'Pretendard', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: $color-primary;
  background-color: $color-background;
}

button {
  cursor: pointer;
}

input,
textarea,
select {
  transition: box-shadow 0.3s ease-in-out;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

img {
  max-width: 100%;
  height: auto;
}
