
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.home {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    height: 3rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    > img {
      width: 3rem;
    }
  }

  &__filter {
    display: flex;
    justify-content: right;
  }

  &__content {
    flex: 1;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--has-data {
      @media (min-width: $breakpoint) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
