
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-modify {
  display: flex;
  flex-direction: column;

  &__back {
    width: 100%;
    height: 100%;
    background-color: $color-section;
    border-radius: $radius;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__preview {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;

    > img {
      width: 15%;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: $radius;
      box-shadow: $shadow;
    }

    > label {
      width: 15%;
      min-width: 15%;
      aspect-ratio: 1/1;
      border: 1px solid $color-secondary;
      border-radius: $radius;
      background-color: $color-background;
      box-shadow: $shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      > svg {
        font-size: 1.2rem;
      }

      > input {
        display: none;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.description {
  &__header {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  &__text {
    width: 100%;
    height: 7rem;
    border: 1px solid $color-secondary;
    border-radius: 4px;
    padding: 0.5rem;
    resize: none;
  }
}
