
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.auth {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  top: 4rem;

  @media (min-width: $breakpoint) {
    width: 70%;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    > img {
      width: 25%;
    }

    > div {
      width: 1px;
      height: 2rem;
      background-color: $color-primary;
    }

    > h1 {
      font-size: 4rem;
      text-align: center;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__link {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
