*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  border: none;
}

input,
textarea,
select {
  border: none;
  outline: none;
}

ul,
ol {
  list-style: none;
}

::-webkit-scrollbar {
  display: none;
}
