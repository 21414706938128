
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  max-width: 520px;
  min-width: 400px;
  background-color: $color-background;
  padding: 1.2rem;
  border-radius: $radius;
  box-shadow: $shadow;
  z-index: 9999;
  display: none;

  &--show {
    display: block;
  }

  &__header {
    display: flex;
    justify-content: right;
    margin-bottom: 0.5rem;
  }
}
