
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.layout {
  height: 100vh;
  min-width: 320px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include mixins.border;
  border-top: none;
  border-bottom: none;

  > * {
    padding: 1.2rem;

    @media (min-width: $breakpoint) {
      padding: 2rem;
    }
  }

  &__main {
    flex: 1;
    overflow-y: scroll;
  }

  &__footer {
    height: 3.5rem;
    background-color: $color-primary;
    display: flex;
    align-items: center;
    z-index: 9999;
  }
}
