
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-card {
  width: 100%;
  height: 300px;
  box-shadow: $shadow;
  border-radius: $radius;
  overflow: hidden;
  position: relative;

  &__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
    padding: 1rem 0.5rem;
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 1rem;
    color: $color-background;
  }
}

.info-title {
  flex: 1;
  font-weight: 300;
  @include mixins.text-ellipsis;
}

.info-price {
  display: flex;
  flex-direction: column;
  align-items: end;

  &__label {
    font-size: 0.75rem;
  }

  &__value {
    font-size: 0.875rem;
    font-weight: 600;
  }
}
