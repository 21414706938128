
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.input {
  width: 100%;
  height: 2rem;
  border: 1px solid $color-secondary;
  border-radius: 4px;
  padding: 0 0.5rem;
}
