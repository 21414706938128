
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-header {
  height: 3rem;
  background-color: $color-section;
  border-radius: $radius;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__modify-group {
    margin-right: 0.5rem;
    display: flex;
    gap: 1rem;
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > svg {
      font-size: 1.4rem;
    }
  }
}
