
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.chat-card {
  border-radius: $radius;
  box-shadow: $shadow;
  display: flex;
  gap: 1rem;
  overflow: hidden;
  @include mixins.border;

  &__product-img {
    width: 20%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  &__info {
    flex: 1;
    padding: 1rem;
    display: flex;
    align-items: end;
    justify-content: space-between;

    &--product {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      > p {
        font-size: 1.2rem;
        font-weight: 300;
      }

      > span {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    &--trader {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      > img {
        width: 2rem;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
