
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.tab-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab {
  width: 100%;
  display: flex;
  align-items: center;

  &__menu {
    flex: 1;
    background-color: $color-section;
    border-radius: $radius $radius 0 0;
    padding: 0.5rem 0;
    @include mixins.border;

    &--active {
      background-color: $color-primary;
      color: $color-background;
    }
  }
}

.tab-content {
  flex: 1;
  background-color: $color-background;
  border-radius: 0 0 $radius $radius;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include mixins.border;
}
