
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-category-dropdown {
  margin: 0.5rem 0;
  width: 6rem;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: $radius;
  @include mixins.border;
}
