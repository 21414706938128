
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product {
  display: flex;
  flex-direction: column;
}

.product-info {
  &__header {
    display: flex;
    align-items: center;

    > h1 {
      flex: 1;
      font-size: 1.5rem;
      font-weight: 600;
    }

    > span {
      min-width: fit-content;
      font-size: 0.875rem;
    }
  }
}

.product-desc {
  padding: 1rem;
  border-radius: $radius;
  @include mixins.border;
}
