
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.my-page {
  min-width: 440px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
  }
}
