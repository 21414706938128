
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.error {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 4rem;
  }
}
