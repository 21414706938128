
        @use 'src/scss/base/mixins' as mixins;
        @use 'src/scss/base/variables' as *;
      
.product-bid-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 2rem;
  color: $color-background;
  font-weight: 600;
  border-radius: $radius;
  box-shadow: $shadow;
  background-color: $color-primary;
  transition: bottom 0.3s ease-in-out;

  @media (min-width: $breakpoint-lg) {
    width: 24rem;
  }

  &--move-up {
    bottom: 5rem;
  }

  .scroll-bottom-marker {
    height: 10px;
  }
}
